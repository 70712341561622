import CustomerNotification from '../components/Notifications/CustomerNotification/CustomerNotification.vue'
import ContactNotification from '../components/Notifications/ContactNotification/ContactNotification.vue'
import SalesDocumentNotification from '../components/Notifications/SalesDocumentNotification/SalesDocumentNotification.vue'
import PaymentNotification from '../components/Notifications/PaymentNotification/PaymentNotification.vue'
import MailNotification from '../components/Notifications/MailNotification/MailNotification.vue'
import CommentNotification from '../components/Notifications/CommentNotification/CommentNotification.vue'
import TaskNotification from '../components/Notifications/TaskNotification/TaskNotification.vue'
import OpportunityNotification from '../components/Notifications/OpportunityNotification/OpportunityNotification.vue'
import ReportNotification from '../components/Notifications/ReportNotification/ReportNotification.vue'
import LetterNotification from '../components/Notifications/LetterNotification/LetterNotification.vue'

const components: KwixeoInterface.NotificationComponents = [
  { element: 'customer', component: CustomerNotification },
  { element: 'contact', component: ContactNotification },
  { element: 'quotation', component: SalesDocumentNotification },
  { element: 'command', component: SalesDocumentNotification },
  { element: 'proforma', component: SalesDocumentNotification },
  { element: 'delivery', component: SalesDocumentNotification },
  { element: 'invoice', component: SalesDocumentNotification },
  { element: 'credit', component: SalesDocumentNotification },
  { element: 'payment', component: PaymentNotification },
  { element: 'mail', component: MailNotification },
  { element: 'comment', component: CommentNotification },
  { element: 'task', component: TaskNotification },
  { element: 'opportunity', component: OpportunityNotification },
  { element: 'report', component: ReportNotification },
  { element: 'letter', component: LetterNotification }
]

const elements: () => KwixeoInterface.NotificationElements = () => ([
  {
    key: 'customer',
    label: 'Tiers (clients, prospects, ...)',
    actions: [
      { key: 'created', label: 'Création d\'un tiers' },
      { key: 'updated', label: 'Modification d\'un tiers' },
      { key: 'transformed', label: 'Transformation d\'un prospect en client' }
    ]
  },
  {
    key: 'contact',
    label: 'Contacts',
    actions: [
      { key: 'created', label: 'Création d\'un contact' },
      { key: 'updated', label: 'Modification d\'un contact' }
    ]
  },
  {
    key: 'quotation',
    label: 'Devis',
    actions: [
      { key: 'created', label: 'Création d\'un devis' },
      { key: 'updated', label: 'Modification d\'un devis' },
      { key: 'validated', label: 'Validation d\'un devis' },
      { key: 'sended', label: 'Envoi d\'un devis' },
      { key: 'accepted', label: 'Acceptation d\'un devis' },
      { key: 'advance-billed', label: 'Facturation de l\'acompte' },
      { key: 'billed', label: 'Facturation du devis' },
      { key: 'refused', label: 'Refus par le client' },
      { key: 'canceled', label: 'Annulation d\'un devis' }
    ]
  },
  {
    key: 'command',
    label: 'Bons de commande',
    actions: [
      { key: 'created', label: 'Création d\'un bon de commande' },
      { key: 'validated', label: 'Validation d\'un bon de commande' },
      { key: 'updated', label: 'Modification d\'un bon de commande' },
      { key: 'sended', label: 'Envoi d\'un bon de commande' },
      { key: 'accepted', label: 'Acceptation d\'un bon de commande' },
      { key: 'advance-billed', label: 'Facturation de l\'acompte' },
      { key: 'billed', label: 'Facturation du bon de commande' },
      { key: 'refused', label: 'Refus par le client' },
      { key: 'canceled', label: 'Annulation d\'un bon de commande' }
    ]
  },
  {
    key: 'proforma',
    label: 'Factures proforma',
    actions: [
      { key: 'created', label: 'Création d\'une facture proforma' },
      { key: 'validated', label: 'Validation d\'une facture proforma' },
      { key: 'updated', label: 'Modification d\'une facture proforma' },
      { key: 'sended', label: 'Envoi d\'une facture proforma' },
      { key: 'accepted', label: 'Acceptation d\'une facture proforma' },
      { key: 'billed', label: 'Facturation d\'une facture proforma' }
    ]
  },
  {
    key: 'delivery',
    label: 'Bons de livraison',
    actions: [
      { key: 'created', label: 'Création d\'un bon de livraison' },
      { key: 'updated', label: 'Modification d\'un bon de livraison' },
      { key: 'validated', label: 'Validation d\'un bon de livraison' },
      { key: 'to-send', label: 'Bon de livraison prêt à être envoyé' },
      { key: 'sended', label: 'Bon de livraison expédié' },
      { key: 'partially-billed', label: 'Bon de livraison facturé partiellement' },
      { key: 'billed', label: 'Facturation du bon de livraison' }
    ]
  },
  {
    key: 'invoice',
    label: 'Factures',
    actions: [
      { key: 'created', label: 'Création d\'une facture' },
      { key: 'updated', label: 'Modification d\'une facture' },
      { key: 'validated', label: 'Validation d\'une facture' },
      { key: 'sended', label: 'Envoi d\'une facture' },
      { key: 'canceled', label: 'Annulation d\'une facture' }
    ]
  },
  {
    key: 'credit',
    label: 'Avoirs',
    actions: [
      { key: 'created', label: 'Création d\'un avoir' },
      { key: 'updated', label: 'Modification d\'un avoir' },
      { key: 'validated', label: 'Validation d\'un avoir' },
      { key: 'sended', label: 'Envoi d\'un avoir' }
    ]
  },
  {
    key: 'payment',
    label: 'Paiements',
    actions: [
      { key: 'created', label: 'Création d\'un paiement' },
      { key: 'updated', label: 'Modification d\'un paiement' },
      { key: 'canceled', label: 'Annulation d\'un paiement' }
    ]
  },
  {
    key: 'comment',
    label: 'Commentaires',
    actions: [
      { key: 'created', label: 'Une note a été créée' },
      { key: 'updated', label: 'Une note a été modifiée' }
    ]
  },
  {
    key: 'mail',
    label: 'Emails',
    actions: [
      { key: 'sended', label: 'Un email a été envoyé' }
    ]
  },
  {
    key: 'task',
    label: 'Tâches',
    actions: [
      { key: 'created', label: 'Une tâche a été créée' },
      { key: 'updated', label: 'Une tâche a été modifiée' },
      { key: 'end', label: 'Une tâche est terminée' },
      { key: 'todo', label: 'Une tâche a été marquée comme à faire' }
    ]
  },
  {
    key: 'opportunity',
    label: 'Opportunitées',
    actions: [
      { key: 'created', label: 'Une opportunité a été créée' },
      { key: 'updated', label: 'Une opportunité a été modifiée' },
      { key: 'moved', label: 'Une opportunité a été déplacée' },
      { key: 'losed', label: 'Une tâche a été marquée comme perdue' },
      { key: 'win', label: 'Une tâche a été marquée comme gagnée' }
    ]
  },
  {
    key: 'report',
    label: 'Comptes-rendus',
    actions: [
      { key: 'created', label: 'Un compte-rendu a été créé' },
      { key: 'updated', label: 'Un compte-rendu a été modifié' }
    ]
  },
  {
    key: 'letter',
    label: 'Lettres',
    actions: [
      { key: 'created', label: 'Une lettre a été créée' },
      { key: 'updated', label: 'Une lettre a été modifiée' }
    ]
  }
])

export {
  components,
  elements
}
