import { Component, Mixins } from 'vue-property-decorator'
import { NotificationMixin, NumberMixin } from '@kwixeo/interface-vue'
import { BBadge, BButton } from 'bootstrap-vue'
import { PaymentItem } from '@kwixeo/sales-vue'

@Component({
  components: {
    BBadge, BButton,
    PaymentItem
  }
})
export default class PaymentNotification extends Mixins(NotificationMixin, NumberMixin) {
  private show = false
}
