import Vue from 'vue'
import { KwixeoInterface } from '@kwixeo/interface-vue'
import router from '@/router'
import axios from '@/axios'
import store from '@/store'
import i18n from '@/lang/i18n'
import configMenu from '@/menu/configMenu'
import sidebarMenu from '@/menu/main'
import headerMenu from '@/menu/header'
import userMenu from '@/menu/user'
import CalendarHeader from '@kwixeo/calendars-vue/src/components/CalendarHeader/CalendarHeader.vue'
import TaskHeader from '@kwixeo/tasks-vue/src/components/TaskHeader/TaskHeader.vue'
import { SendEmailsArea } from '@kwixeo/communication-vue'
import { AdminLogged, CheckSubscription, checkMenuModules, checkRouteAccess, goToHome as subscriptionGoToHome, canCreateUser, showSidebar as subscriptionShowSidebar, showMainSearch } from '@kwixeo/subscriptions-vue'
import { showSidebar as constructionShowSidebar, goToHome as constructionGoToHome } from '@kwixeo/constructions-vue'
import { Store as VueXStore, Store } from 'vuex'
import MainSearch from '../components/MainSearch/MainSearch.vue'
import Configuration from '../components/Configuration/Configuration.vue'
import UserProfileView  from '../views/UserProfileView.vue'
import WelcomeApp from '../components/Welcome/WelcomeApp/WelcomeApp.vue'
import UsersForm from '../components/UsersForm/UsersForm.vue'
import VueRouter from 'vue-router'
import { elements, components } from './notifications'

Vue.use(KwixeoInterface, {
  axios,
  store,
  router,
  i18n,
  /*
  sidebarLogo: require('@/assets/logo-sidebar.png'),
  sidebarHiddenLogo: require('@/assets/logo-sidebar-alt.png'),
  sidebarCollapsedLogo: require('@/assets/logo-sidebar-collapsed.png'),
  publicLogo: require('@/assets/logo-public.png'),
  */
  configMenu,
  configPage: Configuration,
  sidebarMenu,
  userMenu,
  headerMenu,
  headerSecondaryMenu: [
    CalendarHeader,
    TaskHeader
  ],
  appComponents: [
    SendEmailsArea,
    AdminLogged,
    CheckSubscription,
    WelcomeApp
  ],
  searchComponent: MainSearch,
  userProfileView: UserProfileView,
  broadcasts: {
    // PUSHER JS
    // key: process.env.VUE_APP_PUSHER_APP_KEY,
    // cluster: process.env.VUE_APP_PUSHER_CLUSTER

    // Soketi Kwixeo
    // debug: true,
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    wsHost: process.env.VUE_APP_PUSHER_HOST,
    wsPort: process.env.VUE_APP_PUSHER_PORT,
    wssPort: process.env.VUE_APP_PUSHER_PORT,
    forceTLS: false,
    encrypted: true,
    disableStats: true,
    enabledTransports: ['ws', 'wss']
  },
  teamUserFilter: (teamUser: KwixeoFramework.TeamUser) => {
    const user = store.getters['user/teamUser']
    if (user.id === teamUser.id) {
      return true
    } else if (user.permissions['sales-limitation'] === 0) {
      return user.team_users.filter((value: KwixeoFramework.TeamUser) => value.id === teamUser.id).length > 0
    }
    return true
  },
  canCreateUser,
  goToHome: async (router: VueRouter, store: VueXStore<KwixeoInterface.Store>): Promise<void> => {
    if (!await constructionGoToHome(router, store)) {
      await subscriptionGoToHome(router, store)
    }
  },
  showSidebar: (store: Store<KwixeoInterface.Store>): boolean => {
    if (!constructionShowSidebar(store)) {
      return false
    }
    return subscriptionShowSidebar(store)
  },
  showMainSearch,
  checkRouteAccess,
  showMenuEntry: checkMenuModules,
  apiDocumentationUrl: 'https://api.kwixeo.fr/documentation/',
  testModeCanExclude: [
    { value: 'tiers', label: 'Clients, prospects, fournisseurs et contacts' },
    { value: 'catalog', label: 'Produits et prestations' },
    { value: 'contractModels', label: 'Modèles de contrat' },
    { value: 'funnels', label: 'Tunnels de vente' }
  ],
  teamUserComponent: UsersForm,
  appVersion: process.env.VUE_APP_VERSION,
  hasTestMode: true,
  notifications: {
    state: true,
    elements,
    components
  }
} as KwixeoInterface.PluginOptions)
