import { Component, Mixins } from 'vue-property-decorator'
import { NotificationMixin } from '@kwixeo/interface-vue'
import { BBadge, BButton } from 'bootstrap-vue'
import { ReportItem } from '@kwixeo/communication-vue'
import { RawLocation } from 'vue-router'
import { TranslateResult } from 'vue-i18n'

@Component({
  components: {
    BButton, BBadge,
    ReportItem
  }
})
export default class ReportNotification extends Mixins(NotificationMixin) {
  private show = false

  private routeLink (element: KwixeoCommunication.LinkedElement): RawLocation {
    const model = element.data
    switch (element.type) {
      case 'customer':
        return {
          name: 'contacts-customer-item',
          params: {
            type: this.customerType(model),
            id: String(element.id)
          }
        }
      case 'contact':
        return {
          name: 'contacts-contact-item',
          params: {
            id: String(element.id)
          }
        }
      case 'providerCommand':
        return {
          name: 'stock-command-item',
          params: {
            id: String(element.id)
          }
        }
      case 'quotation':
        return {
          name: 'quotation-item',
          params: {
            id: String(element.id)
          }
        }
      case 'command':
        return {
          name: 'command-item',
          params: {
            id: String(element.id)
          }
        }
      case 'deliverie':
        return {
          name: 'delivery-item',
          params: {
            id: String(element.id)
          }
        }
      case 'proforma':
        return {
          name: 'proforma-item',
          params: {
            id: String(element.id)
          }
        }
      case 'invoice':
        return {
          name: 'invoice-item',
          params: {
            id: String(element.id)
          }
        }
      case 'credit':
        return {
          name: 'credit-item',
          params: {
            id: String(element.id)
          }
        }
      case 'charge':
        return {
          name: 'charges-item',
          params: {
            id: String(element.id)
          }
        }
      case 'opportunity':
        return {
          name: 'opportunity-item',
          params: {
            id: String(element.id)
          }
        }
      case 'contract':
        return {
          name: 'contract-item',
          params: {
            id: String(element.id)
          }
        }
      case 'construction':
        return {
          name: 'construction-item',
          params: {
            id: String(element.id)
          }
        }
      default:
        return {}
    }
  }

  private routeLabel (element: KwixeoCommunication.LinkedElement): string|TranslateResult {
    const model = element.data
    switch (element.type) {
      case 'customer':
        return model.name
      case 'contact':
        return model.fullname
      case 'providerCommand':
        return 'Commande fournisseur n°' + model.reference
      case 'quotation':
        return 'Devis n°' + model.reference
      case 'command':
        return 'Bon de commande n°' + model.reference
      case 'deliverie':
        return 'Bon de livraison n°' + model.reference
      case 'proforma':
        return 'Proforma n°' + model.reference
      case 'invoice':
        return 'Facture n°' + model.reference
      case 'credit':
        return 'Avoir n°' + model.reference
      case 'charge':
        return 'Charge n°' + model.reference
      case 'opportunity':
        return 'Opportunité n°' + model.reference
      case 'contract':
        return 'Contrat n°' + model.reference
      case 'construction':
        return 'Chantier n°' + model.reference
    }
    return element.type
  }
  /*
      'charge' => [
          'label'     => 'reference',
          'resource'  => \Kwixeo\Charges\Http\Resources\ChargeResource::class,
      ],
      'opportunity' => [
          'label'     => 'reference',
          'resource'  => \Kwixeo\Opportunities\Http\Resources\OpportunityResource::class,
      ],
      'contract' => [
          'label'     => 'reference',
          'resource'  => \Kwixeo\Sales\Http\Resources\ContractResource::class,
      ],
      'construction' => [
          'label'         => 'reference',
          'resource'      => \Kwixeo\Construction\Http\Resources\ConstructionResource::class,
      ],
   */

  private customerType (customer: KwixeoInterface.GenericObject): string {
    if (customer.reference.customer) {
      return 'customer'
    } else if (customer.reference.prospect) {
      return 'prospect'
    } else if (customer.reference.provider) {
      return 'provider'
    } else {
      return 'subcontractor'
    }
  }
}
