import { Component, Mixins } from 'vue-property-decorator'
import { NotificationMixin } from '@kwixeo/interface-vue'
import { BButton } from 'bootstrap-vue'
import { EmailItem } from '@kwixeo/communication-vue'

@Component({
  components: {
    BButton,
    EmailItem
  }
})
export default class MailNotification extends Mixins(NotificationMixin) {
  private show = false

  get linkedElements (): KwixeoCommunication.LinkedElements {
    return this.value.element?.elements
  }

  get customer (): KwixeoInterface.GenericObject|undefined {
    return this.linkedElements.find(element => element.type === 'customer')?.data
  }

  get contact (): KwixeoInterface.GenericObject|undefined {
    return this.linkedElements.find(element => element.type === 'contact')?.data
  }

  get quotation (): KwixeoInterface.GenericObject|undefined {
    return this.linkedElements.find(element => element.type === 'quotation')?.data
  }

  get command (): KwixeoInterface.GenericObject|undefined {
    return this.linkedElements.find(element => element.type === 'command')?.data
  }

  get proforma (): KwixeoInterface.GenericObject|undefined {
    return this.linkedElements.find(element => element.type === 'proforma')?.data
  }

  get delivery (): KwixeoInterface.GenericObject|undefined {
    return this.linkedElements.find(element => element.type === 'delivery')?.data
  }

  get invoice (): KwixeoInterface.GenericObject|undefined {
    return this.linkedElements.find(element => element.type === 'invoice')?.data
  }

  get credit (): KwixeoInterface.GenericObject|undefined {
    return this.linkedElements.find(element => element.type === 'credit')?.data
  }
}
