import { Component, Mixins } from 'vue-property-decorator'
import { NotificationMixin } from '@kwixeo/interface-vue'

@Component
export default class CustomerNotification extends Mixins(NotificationMixin) {
  get customerType (): string {
    const customer = this.value.element as KwixeoCustomers.Customer
    if (customer.reference.customer) {
      return 'customer'
    } else if (customer.reference.prospect) {
      return 'prospect'
    } else if (customer.reference.provider) {
      return 'provider'
    } else {
      return 'subcontractor'
    }
  }
}
