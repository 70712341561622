import { Component, Mixins } from 'vue-property-decorator'
import { NotificationMixin, NumberMixin } from '@kwixeo/interface-vue'


@Component
export default class SalesDocumentNotification extends Mixins(NotificationMixin, NumberMixin) {
  get type (): string {
    switch (this.value.element_type) {
      case 'credit':
        return 'credit'
      case 'proforma':
      case 'invoice':
        return 'female'
      default:
        return 'male'
    }
  }
}
