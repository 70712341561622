import { library } from '@fortawesome/fontawesome-svg-core'

import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock'
import { faCreditCard } from '@fortawesome/pro-solid-svg-icons/faCreditCard'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faAlarmExclamation } from '@fortawesome/pro-solid-svg-icons/faAlarmExclamation'
import { faCommentAlt } from '@fortawesome/pro-solid-svg-icons/faCommentAlt'
import { faPlug } from '@fortawesome/pro-solid-svg-icons/faPlug'
import { faBell } from '@fortawesome/pro-solid-svg-icons/faBell'

import { faArrowsAlt } from '@fortawesome/pro-solid-svg-icons/faArrowsAlt'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'

library.add(faLock, faCreditCard, faCheck, faAlarmExclamation, faCommentAlt, faPlug, faBell, faArrowsAlt, faTimes)
