import { Component, Mixins } from 'vue-property-decorator'
import { NotificationMixin } from '@kwixeo/interface-vue'
import { TaskItem } from '@kwixeo/tasks-vue'

@Component({
  components: {
    TaskItem
  }
})
export default class TaskNotification extends Mixins(NotificationMixin) {
}
