import { Component, Mixins } from 'vue-property-decorator'
import { NotificationMixin } from '@kwixeo/interface-vue'
import { FroalaView } from '@kwixeo/form-vue'
import { BButton } from 'bootstrap-vue'
import { PlanificationItem } from '@kwixeo/constructions-vue'

@Component({
  components: {
    FroalaView,
    BButton,
    PlanificationItem
  }
})
export default class CommentNotification extends Mixins(NotificationMixin) {
  private showPlanification = false

  get type (): string {
    return this.value?.element?.element_type ?? ''
  }

  get element (): KwixeoInterface.GenericObject {
    return this.value.element?.element
  }

  get customerType (): string {
    const customer = this.element as KwixeoCustomers.Customer
    if (customer.reference.customer) {
      return 'customer'
    } else if (customer.reference.prospect) {
      return 'prospect'
    } else if (customer.reference.provider) {
      return 'provider'
    } else {
      return 'subcontractor'
    }
  }
}
